import { UrlObject } from 'node:url';

import { Flex, Link as ChakraLink } from '@chakra-ui/react';
import NextLink from 'next/link';
import React, { FunctionComponent, ReactElement, ReactNode } from 'react';

import { LinkType } from '../../lib/enums/LinkType';
import { IconObject } from '../../lib/Icons';
import { IconComp } from './IconComp';

interface LinkProps {
  href: UrlObject | string;
  children: ReactNode;
  type: LinkType;
  isDisabled?: boolean;
  iconName?: keyof typeof IconObject;
  colorScheme?: string;
  weight?: string;
  download?: string;
  ariaLabel?: string;
  shallow?: boolean;
  isExternal?: boolean;
  as?: string;
  locale?: string;
  onClick?: (e) => void;
}

export const Link: FunctionComponent<LinkProps> = ({
  href,
  isDisabled = false,
  children,
  type,
  iconName,
  colorScheme = 'cyan.web',
  weight = 'normal',
  download,
  ariaLabel,
  shallow = false,
  isExternal = false,
  as,
  locale,
  onClick,
}): ReactElement => {
  return isDisabled ? (
    getStyledChakraLink(
      isDisabled,
      children,
      type,
      ariaLabel,
      iconName,
      colorScheme,
      weight,
      download,
      isExternal,
      as,
      onClick,
    )
  ) : (
    <NextLink
      legacyBehavior
      passHref
      shallow={shallow}
      href={href}
      as={as ?? href}
      locale={locale ?? process.env.NEXT_PUBLIC_DEFAULT_LOCALE!}
      // prefetch={true}
    >
      {getStyledChakraLink(
        isDisabled,
        children,
        type,
        ariaLabel,
        iconName,
        colorScheme,
        weight,
        download,
        isExternal,
        as ?? (href as string),
        onClick,
      )}
    </NextLink>
  );
};

export const getStyledChakraLink = (
  isDisabled: boolean,
  children: ReactNode,
  type: LinkType,
  ariaLabel?: string,
  iconName?: keyof typeof IconObject,
  colorScheme?: string,
  weight?: string,
  download?: string,
  isExternal?: boolean,
  as?: string,
  onClick?: (e) => void,
) => {
  return (
    <ChakraLink
      data-group
      position='relative'
      left={LinkType.Menu === type ? '-10px' : undefined}
      transition='transform 0.3s'
      variant={isDisabled ? type + 'Disabled' : type}
      colorScheme={LinkType.Item === type ? 'black' : colorScheme}
      download={download}
      aria-label={ariaLabel}
      role='button'
      pointerEvents={isDisabled ? 'none' : 'auto'}
      fontWeight={weight}
      textStyle={LinkType.Menu === type ? 'desktop.body.book.l' : 'desktop.link'}
      isExternal={isExternal}
      pr='30px'
      py='0'
      _hover={LinkType.Menu === type ? { transform: 'translateX(30px)', color: 'cyan.web' } : undefined}
      _focus={LinkType.Menu === type ? { transform: 'translateX(30px)', color: 'cyan.web' } : undefined}
      onClick={(e) => onClick && onClick(e)}
    >
      {iconName && LinkType.SecondaryButton === type && !isDisabled && (
        <IconComp
          iconName={iconName}
          chakraStyle={{
            display: LinkType.SecondaryButton === type ? 'flex' : 'none',
            pr: 'xs',
            transition: 'all .5s ease-in-out',
            _groupHover: { display: 'flex', pr: '5px', pl: '15px' },
            _groupFocus: { display: 'flex' },
            path: {
              stroke: isDisabled ? 'mountain.60' : colorScheme,
            },
          }}
        ></IconComp>
      )}

      {iconName && LinkType.Menu === type && !isDisabled && (
        <IconComp
          iconName={iconName}
          chakraStyle={{
            svg: {
              height: '25px',
              width: '25px',
            },
            opacity: '0',
            pr: 'xxs',
            transition: 'all .5s ease-in-out',
            _groupHover: {
              opacity: '1',
              path: {
                stroke: isDisabled ? 'mountain.60' : colorScheme,
              },
            },
            _groupFocus: {
              opacity: '1',
              path: {
                stroke: isDisabled ? 'mountain.60' : colorScheme,
              },
            },
            path: {
              stroke: isDisabled ? 'mountain.60' : 'mountain.base',
            },
          }}
        ></IconComp>
      )}
      <Flex aria-label={ariaLabel}>{children}</Flex>
    </ChakraLink>
  );
};
