import { Translations } from 'store/translations';

export const internalPages = (translations: Translations, codename?: string, locale?: string): string | null => {
  if (codename) {
    const pages = {
      offices_list: `/${translations.multiple('contactUsUrl')}`,
      offices_map: `/${locale}/${translations.multiple('contactUsUrl')}/${translations.url('mapUrl')}`,
      people: `/${translations.multiple('contactUsUrl')}/${translations.url('peopleUrl')}`,
      projects: `/${translations.multiple('project')}`,
      news: `/${translations.multiple('news')}`,
      products: `/${translations.multiple('product')}`,
      insights: `/${translations.multiple('insight')}`,
    };
    return pages[codename];
  }
  return null;
};
