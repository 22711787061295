import { defineStyle } from '@chakra-ui/react';

export const withCloseIcon = defineStyle({
  closeButton: {
    display: 'block',
  },
});
export const withoutCloseIcon = defineStyle({
  closeButton: {
    display: 'none',
  },
});

export const Modal = {
  baseStyle: {
    body: {
      p: '0',
    },
  },
  variants: {
    withCloseIcon,
    withoutCloseIcon,
  },
  defaultProps: {
    variant: 'withCloseIcon',
  },
};
