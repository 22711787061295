import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';
const helpers = createMultiStyleConfigHelpers(['tabs', 'tab', 'tabpanel', 'tablist']);

export const Tabs = helpers.defineMultiStyleConfig({
  baseStyle: {
    tabs: {
      px: 0,
    },
    tablist: {
      borderBottom: '3px solid',
      borderColor: 'mountain.20',
    },
    tab: {
      py: 'xxs',
      px: 'xs',
      textStyle: 'mobile.body.s',
      _selected: {
        color: 'cyan.web',
        borderColor: 'cyan.web',
        borderBottomWidth: '3px',
      },
    },
    tabpanel: {
      pt: '10px',
      px: 0,
    },
  },
  sizes: {
    base: {
      tabs: {
        px: 0,
      },
      tab: {
        py: 'xxs',
        px: 'xs',
      },
      tabpanel: {
        pt: '10px',
        px: 0,
      },
    },
    md: {
      tabs: {
        px: '0',
      },
      tab: {
        textStyle: 'desktop.body.book.l',
        py: 'xxs',
        px: '30px',
      },
      tabpanel: {
        px: 0,
        pt: 'm',
      },
    },
  },
  defaultProps: {
    size: 'base',
  },
});
