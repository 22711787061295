import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

export const onWhite = defineStyle({
  bg: 'gray.red',
});
export const onColor = defineStyle({
  bg: 'white',
});

export const textareaTheme = defineStyleConfig({
  baseStyle: {
    color: 'mountain.base',
    borderColor: 'mountain.20',
    borderWidth: '1px',
    borderRadius: '0px',
    rounded: 'none',
    _placeholder: {
      color: 'mountain.60',
      _groupHover: {
        color: 'mountain.base',
      },
    },
    _focus: {
      borderColor: 'cyan.web',
      borderWidth: '2px',
    },
    _invalid: {
      borderColor: 'field',
      borderWidth: '2px',
      _focus: {
        borderColor: 'cyan.web',
        borderWidth: '2px',
      },
    },
    _disabled: {
      _placeholder: {
        color: 'mounyain.40',
      },
    },
  },
  variants: {
    onWhite,
    onColor,
  },
  defaultProps: {
    variant: 'onWhite',
  },
});
