// import { ComponentStyleConfig } from '@chakra-ui/react';
import { defineStyleConfig } from '@chakra-ui/react';

export const Form = defineStyleConfig({
  // The styles all button have in common
  baseStyle: {
    label: {
      color: 'mountain.base',
      textStyle: 'mobile.body.xs',
    },
    '.chakra-form__error-message': {
      color: 'field',
      fontSize: '12px',
      lineHeight: '14px',
      fontWeight: 325,
      fontFamily: 'HCo Gotham Rounded Book',
    },
  },
});
// export const Form: ComponentStyleConfig = {
//   baseStyle: {
//     container: {
//       label: {
//         color: 'mountain.base',
//         textStyle: 'mobile.body.xs',
//       },
//       '.chakra-form__error-message': {
//         color: 'field',
//         fontSize: '12px',
//         lineHeight: '14px',
//         fontWeight: 325,
//         fontFamily: 'HCo Gotham Rounded Book',
//       },
//     },
//   },
// };
