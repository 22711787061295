export const colors = {
  black: '#000',
  white: '#ffffff',
  gray: {
    98: '#FAFAFA',
    red: '#F8F8F8',
  },
  cyan: {
    web: '#0098eb',
    a11y: '#007bbd',
    ramboll: '#009df0',
    10: '#e5f5fe',
    20: '#ccebfc',
  },
  ocean: {
    base: '#05326e',
    10: '#e6eaf0',
    20: '#cdd6e2',
  },
  forest: {
    base: '#125a40',
    10: '#e7eeec',
    20: '#d0ded9',
  },
  mountain: {
    base: '#273943',
    10: '#e9ebec',
    20: '#d4d7d9',
    40: '#a9b0b4',
    60: '#7d888e',
    80: '#526169',
    get lowAlpha() {
      return `${this.base}4D`;
    },
  },
  pebble: {
    base: '#e3e1d8',
    10: '#fcfcfb',
    20: '#f9f9f7',
    40: '#f3f1ed',
    60: '#eeede8',
    80: '#e9e7e0',
  },
  field: '#FF8855',
};

export const spacing = {
  space: {
    '3xs': '5px',
    xxs: '10px',
    xs: '20px',
    s: '40px',
    m: '60px',
    l: '80px',
    lr: '100px',
    xl: '120px',
  },
};

export const fonts = {
  heading: `'HCo Gotham Rounded Medium', sans-serif`,
  body: `'HCo Gotham Rounded Light', sans-serif`,
  book: `sans-serif, 'HCo Gotham Rounded Book'`,
};

export const textStyles = {
  mobile: {
    campaign: {
      h1: {
        fontFamily: 'HCo Gotham Rounded Medium',
        fontStyle: 'normal',
        fontSize: '60px',
        lineHeight: '64px',
      },
    },
    poland_office: {
      fontFamily: 'Helvetica Neue, Helvetica, Arial, sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '20px',
      lineHeight: '28px',
      letterSpacing: '-0.04em',
    },
    h1: {
      fontFamily: 'HCo Gotham Rounded Light',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '60px',
      lineHeight: '64px',
    },
    poland_h1: {
      fontFamily: 'Helvetica Neue, Helvetica, Arial, sans-serif',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '60px',
      lineHeight: '64px',
    },
    h2: {
      fontFamily: 'HCo Gotham Rounded Light',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '40px',
      lineHeight: '48px',
      letterSpacing: '-0.04em',
    },
    poland_h2: {
      fontFamily: 'Helvetica Neue, Helvetica, Arial, sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '20px',
      lineHeight: '28px',
      letterSpacing: '-0.04em',
    },
    h3: {
      fontFamily: 'HCo Gotham Rounded Light',
      fontStyle: 'normal',
      fontWeight: 300,
      fontSize: '36px',
      lineHeight: '44px',
      letterSpacing: '-0.04em',
    },
    poland_h3: {
      fontFamily: 'Helvetica Neue, Helvetica, Arial, sans-serif',
      fontStyle: 'normal',
      fontWeight: 300,
      fontSize: '36px',
      lineHeight: '44px',
      letterSpacing: '-0.04em',
    },
    h4: {
      fontFamily: 'HCo Gotham Rounded Book',
      fontStyle: 'normal',
      fontWeight: 325,
      fontSize: '24px',
      lineHeight: '32px',
      letterSpacing: '-0.04em',
    },
    poland_h4: {
      fontFamily: 'Helvetica Neue, Helvetica, Arial, sans-serif',
      fontStyle: 'normal',
      fontWeight: 325,
      fontSize: '24px',
      lineHeight: '32px',
      letterSpacing: '-0.04em',
    },
    h5: {
      fontFamily: 'HCo Gotham Rounded Book',
      fontStyle: 'normal',
      fontWeight: 325,
      fontSize: '20px',
      lineHeight: '28px',
      letterSpacing: '-0.04em',
    },
    poland_h5: {
      fontFamily: 'Helvetica Neue, Helvetica, Arial, sans-serif',
      fontStyle: 'normal',
      fontWeight: 325,
      fontSize: '20px',
      lineHeight: '28px',
      letterSpacing: '-0.04em',
    },
    h6: {
      fontFamily: 'HCo Gotham Rounded Medium',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '20px',
      lineHeight: '28px',
    },
    poland_h6: {
      fontFamily: 'Helvetica Neue, Helvetica, Arial, sans-serif',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '20px',
      lineHeight: '28px',
    },
    body: {
      l: {
        fontFamily: 'HCo Gotham Rounded Book',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '20px',
        lineHeight: '28px',
      },
      s: {
        fontFamily: 'HCo Gotham Rounded Book',
        fontStyle: 'normal',
        fontWeight: 325,
        fontSize: '16px',
        lineHeight: '24px',
      },
      xs: {
        fontFamily: 'HCo Gotham Rounded Book',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '20px',
      },
      medium: {
        l: {
          fontFamily: 'HCo Gotham Rounded Medium',
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: '20px',
          lineHeight: '28px',
        },
        s: {
          fontFamily: 'HCo Gotham Rounded Medium',
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: '16px',
          lineHeight: '24px',
        },
      },
    },
    contact_poland: {
      fontFamily: 'Helvetica Neue, Helvetica, Arial, sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '20px',
      lineHeight: '28px',
      letterSpacing: '-0.02em',
    },
    formatted_polska: {
      fontFamily: 'Helvetica Neue, Helvetica, Arial, sans-serif',
      fontStyle: 'normal',
      fontWeight: 325,
      fontSize: '16px',
      lineHeight: '24px',
    },
  },
  tablet: {
    h1: {
      fontFamily: 'HCo Gotham Rounded Light',
      fontStyle: 'normal',
      fontWeight: 300,
      fontSize: '96px',
      lineHeight: '96px',
      letterSpacing: '-0.04em',
    },
    h2: {
      fontFamily: 'HCo Gotham Rounded Light',
      fontStyle: 'normal',
      fontWeight: 300,
      // WAS 60
      fontSize: '80px',
      lineHeight: '72px',
      letterSpacing: '-0.04em',
    },
    h3: {
      fontFamily: 'HCo Gotham Rounded Light',
      fontStyle: 'normal',
      fontWeight: 300,
      fontSize: '40px',
      lineHeight: '48px',
      letterSpacing: '-0.04em',
    },
    h4: {
      fontFamily: 'HCo Gotham Rounded Book',
      fontStyle: 'normal',
      fontWeight: 325,
      fontSize: '32px',
      lineHeight: '40px',
      letterSpacing: '-0.04em',
    },
    h5: {
      fontFamily: 'HCo Gotham Rounded Book',
      fontStyle: 'normal',
      fontWeight: 325,
      fontSize: '24px',
      lineHeight: '32px',
      letterSpacing: '-0.04em',
    },
    h6: {
      fontFamily: 'HCo Gotham Rounded Medium',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '20px',
      lineHeight: '28px',
      letterSpacing: '-0.02em',
    },
    body: {
      l: {
        fontFamily: 'HCo Gotham Rounded Book',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '20px',
        lineHeight: '28px',
        letterSpacing: '-0.02em',
      },
      s: {
        fontFamily: 'HCo Gotham Rounded Book',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '20px',
        letterSpacing: '0',
      },
      xs: {
        fontFamily: 'HCo Gotham Rounded Book',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '20px',
        letterSpacing: '0',
      },
      medium: {
        l: {
          fontFamily: 'HCo Gotham Rounded Medium',
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: '20px',
          lineHeight: '28px',
          letterSpacing: '-0.02em',
        },
        s: {
          fontFamily: 'HCo Gotham Rounded Medium',
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: '16px',
          lineHeight: '20px',
          letterSpacing: '0',
        },
      },
    },
  },
  desktop: {
    // TODO: TEMPORARY REMOVE THIS
    campaign: {
      h2: {
        fontFamily: 'HCo Gotham Rounded Medium',
        fontStyle: 'normal',
        // TODO: CRITICAL!! TEMP SOLUTION (should be 400)
        fontSize: '80px',
        lineHeight: '80px',
        letterSpacing: '-0.04em',
      },
    },
    poland_office: {
      fontFamily: 'Helvetica Neue, Helvetica, Arial, sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '20px',
      lineHeight: '28px',
      letterSpacing: '-0.04em',
    },
    contact_poland: {
      fontFamily: 'Helvetica Neue, Helvetica, Arial, sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '20px',
      lineHeight: '28px',
      letterSpacing: '-0.02em',
    },
    format_poland: {
      fontFamily: 'Helvetica Neue, Helvetica, Arial, sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '20px',
      lineHeight: '28px',
      letterSpacing: '-0.04em',
    },
    h1: {
      fontFamily: 'HCo Gotham Rounded Light',
      fontStyle: 'normal',
      fontWeight: 300,
      fontSize: '120px',
      lineHeight: '120px',
      letterSpacing: '-0.04em',
    },
    poland_h1: {
      fontFamily: 'Helvetica Neue, Helvetica, Arial, sans-serif',
      fontStyle: 'normal',
      fontWeight: 300,
      fontSize: '120px',
      lineHeight: '120px',
      letterSpacing: '-0.04em',
    },
    h2: {
      fontFamily: 'HCo Gotham Rounded Light',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '80px',
      lineHeight: '80px',
      letterSpacing: '-0.04em',
    },
    poland_h2: {
      fontFamily: 'Helvetica Neue, Helvetica, Arial, sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '80px',
      lineHeight: '80px',
      letterSpacing: '-0.04em',
    },
    h3: {
      fontFamily: 'HCo Gotham Rounded Light',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '60px',
      lineHeight: '68px',
      letterSpacing: '-0.04em',
    },
    poland_h3: {
      fontFamily: 'Helvetica Neue, Helvetica, Arial, sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '60px',
      lineHeight: '68px',
      letterSpacing: '-0.04em',
    },
    h4: {
      fontFamily: 'HCo Gotham Rounded Book',
      fontStyle: 'normal',
      fontWeight: 325,
      fontSize: '40px',
      lineHeight: '48px',
      letterSpacing: '-0.04em',
    },
    poland_h4: {
      fontFamily: 'Helvetica Neue, Helvetica, Arial, sans-serif',
      fontStyle: 'normal',
      fontWeight: 325,
      fontSize: '40px',
      lineHeight: '48px',
      letterSpacing: '-0.04em',
    },
    h5: {
      fontFamily: 'HCo Gotham Rounded Book',
      fontStyle: 'normal',
      fontWeight: 325,
      fontSize: '30px',
      lineHeight: '32px',
      letterSpacing: '-0.04em',
    },
    poland_h5: {
      fontFamily: 'Helvetica Neue, Helvetica, Arial, sans-serif',
      fontStyle: 'normal',
      fontWeight: 325,
      fontSize: '30px',
      lineHeight: '32px',
      letterSpacing: '-0.04em',
    },
    h6: {
      fontFamily: 'HCo Gotham Rounded Medium',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '20px',
      lineHeight: '28px',
      letterSpacing: '-0.04em',
    },
    poland_h6: {
      fontFamily: 'Helvetica Neue, Helvetica, Arial, sans-serif',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '20px',
      lineHeight: '28px',
      letterSpacing: '-0.04em',
    },
    body: {
      l: {
        fontFamily: 'HCo Gotham Rounded Light',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '20px',
        lineHeight: '28px',
        letterSpacing: '-0.04em',
      },
      formatted_polska: {
        fontFamily: 'Helvetica Neue, Helvetica, Arial, sans-serif',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '20px',
        lineHeight: '28px',
        letterSpacing: '-0.02em',
      },
      s: {
        fontFamily: 'HCo Gotham Rounded Light',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '24px',
        letterSpacing: '0',
      },
      xs: {
        fontFamily: 'HCo Gotham Rounded Light',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '20px',
        letterSpacing: '0',
      },
      medium: {
        l: {
          fontFamily: 'HCo Gotham Rounded Medium',
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: '20px',
          lineHeight: '28px',
          letterSpacing: '-0.02em',
        },
        s: {
          fontFamily: 'HCo Gotham Rounded Medium',
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: '16px',
          lineHeight: '20px',
        },
      },
      book: {
        l: {
          fontFamily: 'HCo Gotham Rounded Book',
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: '20px',
          lineHeight: '28px',
          letterSpacing: '-0.02em',
        },

        s: {
          fontFamily: 'HCo Gotham Rounded Book',
          fontStyle: 'normal',
          fontWeight: 325,
          fontSize: '16px',
          lineHeight: '20px',
        },
      },
    },
    button: {
      fontFamily: 'HCo Gotham Rounded Medium',
      fontStyle: 'normal',
      fontWeight: 350,
      fontSize: '18px',
      lineHeight: '20px',
    },
    link: {
      fontFamily: 'HCo Gotham Rounded Medium',
      fontStyle: 'normal',
      fontWeight: 350,
      fontSize: '16px',
      lineHeight: '20px',
    },
  },
};

export const breakpoints = {
  sm: '640px',
  md: '768px',
  '1md': '820px',
  '2md': '900px',
  lg: '1024px',
  xl: '1280px',
  '2xl': '1440px',
  '3xl': '1920px',
};

export const moduleMaxWidth = '1920px';
