export const geoCountries = [
  {
    regionCode: 'DK',
    locale: 'da-dk',
    hreflang: 'da-dk',
    label: 'Denmark',
    origin: 'Danish',
  },
  {
    regionCode: 'DE',
    locale: 'de-de',
    hreflang: 'de-de',
    label: 'Germany',
    origin: 'German',
  },
  {
    regionCode: 'FI',
    locale: 'fi-fi',
    hreflang: 'fi-fi',
    label: 'Finland',
    origin: 'Finnish',
  },
  {
    regionCode: 'SE',
    locale: 'sv-se',
    hreflang: 'sv-se',
    label: 'Sweden',
    origin: 'Swedish',
  },
  {
    regionCode: 'NO',
    locale: 'no-no',
    hreflang: 'no-no',
    label: 'Norway',
    origin: 'Norwegian',
  },
  {
    regionCode: 'UK',
    locale: 'en-gb',
    hreflang: 'en-gb',
    label: 'United Kingdom',
    origin: 'British',
  },
  {
    regionCode: 'US',
    locale: 'en-us',
    hreflang: 'en-us',
    label: 'United States',
    origin: 'American',
  },
  {
    regionCode: 'SG',
    locale: 'en-apac',
    hreflang: 'en-sg',
    label: 'Singapore',
    origin: 'Singaporean',
  },
  {
    regionCode: 'JP',
    locale: 'en-apac',
    hreflang: 'en-jp',
    label: 'Japan',
    origin: 'Singaporean',
  },
  {
    regionCode: 'ID',
    locale: 'en-apac',
    hreflang: 'en-in',
    label: 'Indonesia',
    origin: 'Singaporean',
  },
  {
    regionCode: 'AU',
    locale: 'en-apac',
    hreflang: 'en-au',
    label: 'Australia',
    origin: 'Singaporean',
  },
  {
    regionCode: 'NZ',
    locale: 'en-apac',
    hreflang: 'en-nz',
    label: 'New Zealand',
    origin: 'Singaporean',
  },
  {
    regionCode: 'CN',
    locale: 'en-apac',
    hreflang: 'en-ch',
    label: 'China',
    origin: 'Singaporean',
  },
  {
    regionCode: '*',
    locale: 'en',
    hreflang: 'en',
    label: 'Global',
    origin: 'Global',
  },
];
