import { inputAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react';

const { defineMultiStyleConfig, definePartsStyle } = createMultiStyleConfigHelpers(inputAnatomy.keys);

const fieldBase = {
  color: 'mountain.base',
  borderColor: 'mountain.20',
  borderWidth: '1px',
  borderRadius: '0px',
  rounded: 'none',
  _placeholder: {
    color: 'mountain.60',
    _groupHover: {
      color: 'mountain.base',
    },
  },
  _focus: {
    borderColor: 'cyan.web',
    borderWidth: '2px',
  },
  _invalid: {
    borderColor: 'field',
    borderWidth: '2px',
    _focus: {
      borderColor: 'cyan.web',
      borderWidth: '2px',
    },
  },
  _disabled: {
    _placeholder: {
      color: 'mounyain.40',
    },
  },
};

export const onWhite = defineStyle({
  field: {
    bg: 'gray.red',
  },
});
export const onColor = defineStyle({
  field: {
    bg: 'white',
  },
});

export const inputTheme = defineMultiStyleConfig({
  baseStyle: {
    field: {
      ...fieldBase,
    },
    element: {},
  },
  variants: {
    onWhite,
    onColor,
  },
  defaultProps: {
    variant: 'onWhite',
  },
});

const noBorderRadius = {
  borderRadius: 0,
};

const fullHeight = {
  h: 'full',
};

const smElement = defineStyle({
  ...fullHeight,
  w: '25px',
});

const mdElement = defineStyle({
  ...fullHeight,
  w: '35px',
});

const lgElement = defineStyle({
  ...fullHeight,
  w: '50px',
});

const smField = defineStyle({
  ...noBorderRadius,
  pr: 's',
});

const mdField = defineStyle({
  ...noBorderRadius,
  pr: 's',
});

const lgField = defineStyle({
  ...noBorderRadius,
  pr: 'm',
});

const sizes = {
  sm: definePartsStyle({ element: smElement, field: smField }),
  md: definePartsStyle({ element: mdElement, field: mdField }),
  lg: definePartsStyle({ element: lgElement, field: lgField }),
};

export const Input = defineMultiStyleConfig({ sizes });
