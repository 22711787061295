export const languageMapper = {
  en: 'en',
  'en-us': 'en-us',
  'en-gb': 'en-gb',
  'fi-fi': 'fi-fi',
  'da-dk': 'da-dk',
  'sv-se': 'sv-se',
  'no-no': 'no-no',
  'de-de': 'de-de',
  'en-jp': 'en-apac',
  'en-au': 'en-apac',
  'en-nz': 'en-apac',
  'en-ch': 'en-apac',
  'en-sg': 'en-apac',
  'en-in': 'en-apac',
  'en-my': 'en-apac',
  'en-kr': 'en-apac',
};
