import { camelCasePropertyNameResolver, DeliveryClient } from '@kontent-ai/delivery-sdk';

const projectId = process.env.NEXT_PUBLIC_KONTENT_AI_PROD_PROJECT_ID || '';
const apiKey = process.env.NEXT_PUBLIC_KONTENT_AI_PROD_API_KEY;
const previewApiKey = process.env.NEXT_PUBLIC_KONTENT_AI_PROD_PREVIEW_API_KEY;

const defaultQueryConfig =
  process.env.NEXT_PUBLIC_APP_ENV === 'development' || process.env.NODE_ENV === 'development'
    ? {
        usePreviewMode: true,
        useSecuredMode: false,
      }
    : {
        usePreviewMode: false,
        useSecuredMode: true,
      };

const PrevClient = new DeliveryClient({
  projectId: projectId,
  defaultQueryConfig: {
    usePreviewMode: true,
  },
  secureApiKey: apiKey,
  previewApiKey,
  propertyNameResolver: camelCasePropertyNameResolver,
});

const ProdClient = new DeliveryClient({
  projectId: projectId,
  defaultQueryConfig,
  secureApiKey: apiKey,
  previewApiKey: previewApiKey,
  propertyNameResolver: camelCasePropertyNameResolver,
});

export { PrevClient, ProdClient };
