import PiwikProProvider from '@piwikpro/next-piwik-pro';
import Footer, { FooterProps } from 'components/modules/Footer';
import { NavBar, NavProps } from 'components/nav/NavBar';
import { fetchNavData } from 'components/nav/NavFetching';
import { PropsWithChildren } from 'react';
import getFooterData from 'utils/normalization/footer';
import { GetContentPage } from 'utils/sitemap';
// const PiwikProProvider = require('@piwikpro/next-piwik-pro');

export type LayoutType = {
  navigation: Awaited<ReturnType<typeof fetchNavData>>;
  footer: Awaited<ReturnType<typeof getFooterData>>;
};

interface LayoutDefaultProps {
  footer?: FooterProps;
  navMenu?: NavProps;
  hasFrontpageCarousel?: boolean;
  variants?: Awaited<ReturnType<typeof GetContentPage.allLocales>>;
}

const LayoutDefault = ({
  children,
  footer,
  navMenu,
  hasFrontpageCarousel,
  variants,
}: PropsWithChildren<LayoutDefaultProps>) => {
  return (
    <>
      <PiwikProProvider
        containerUrl={process.env.NEXT_PUBLIC_CONTAINER_URL}
        containerId={process.env.NEXT_PUBLIC_CONTAINER_ID}
      >
        {navMenu && (
          <NavBar
            key={hasFrontpageCarousel ? 'transparent-visual' : 'white-visual'}
            navProps={navMenu.navProps}
            megaMenuProps={navMenu.megaMenuProps}
            languageSelectors={navMenu.languageSelectors}
            mainSearch={navMenu.mainSearch}
            isCampaign={navMenu.isCampaign}
            variants={variants}
            hasFrontpageCarousel={hasFrontpageCarousel}
            shouldHideSearch={navMenu.shouldHideSearch}
          />
        )}
        <main>{children}</main>
        {footer && <Footer {...footer} />}
      </PiwikProProvider>
    </>
  );
};

export default LayoutDefault;
