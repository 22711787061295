import { defineStyle, StyleFunctionProps } from '@chakra-ui/react';

import { disabledBase, focusBase, hoverBase } from './link';

export const primary = defineStyle({
  bg: 'cyan.web',
  _hover: {
    bg: 'cyan.a11y',
  },
  _focusVisible: {
    bg: 'cyan.a11y',
    borderColor: 'mountain.base',
    borderWidth: '3px',
    borderStyle: 'solid',
    boxShadow: 'none',
  },
  _disabled: {
    bg: 'mountain.10',
    color: 'mountain.60',
    opacity: 1,
  },
});

const secondary = defineStyle((props: StyleFunctionProps) => {
  return {
    pl: 0,
    pr: 'xxs',
    ...focusBase(props),
    ...hoverBase(props),
    textStyle: 'desktop.body.medium.s',
    color: props.colorScheme,
    _disabled: {
      ...disabledBase,
      _hover: {
        color: 'mountain.60',
      },
    },
  };
});

export const Button = {
  baseStyle: {
    w: 'fit-content',
    h: 'fit-content',
    color: 'white',
    borderRadius: 0,
    lineHeight: 'xs',
    iconSpacing: 'xxs',
  },
  sizes: {
    large: {
      height: '100px',
      p: 's',
      fontFamily: 'HCo Gotham Rounded Medium',
      fontStyle: 'normal',
      fontWeight: 350,
      fontSize: '20px',
      lineHeight: '20px',
    },
    mobile: {
      minH: '64px',
      p: 'xs',
    },
    medium: {
      minH: '60px',
      p: 'xs',
    },
  },
  variants: {
    primary,
    secondary,
  },
  defaultProps: {
    variant: 'primary',
  },
};
