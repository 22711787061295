const localHost = process.env.NEXT_PUBLIC_HOST_ROUTE_LOCAL!;
const devHost = process.env.NEXT_PUBLIC_HOST_ROUTE_DEV!;
const stageHost = process.env.NEXT_PUBLIC_HOST_ROUTE_STAGE!;
const prodHost = process.env.NEXT_PUBLIC_HOST_ROUTE_PROD!;

export const getHost = (window?: Window & typeof globalThis) => {
  if (window) return window.location.origin ? window.location.origin : '';
  switch (process.env.NEXT_PUBLIC_APP_ENV) {
    case 'development':
      return devHost;
    case 'production':
      return prodHost;
    case 'staging':
      return stageHost;
    default:
      return localHost;
  }
};

export const getApiRoute = () => {
  switch (process.env.NEXT_PUBLIC_APP_ENV) {
    case 'development':
      return devHost + '/api';
    case 'production':
      return prodHost + '/api';
    case 'staging':
      return stageHost + '/api';
    default:
      return localHost + '/api';
  }
};
