import { getTranslations } from 'helpers/translations';
import { Translations } from 'store/translations';

export const setZustandTranslations = async (
  setState: Translations,
  locale = process.env.NEXT_PUBLIC_DEFAULT_LOCALE!,
) => {
  const {
    url,
    aria,
    category,
    common,
    content_type,
    location,
    multiple,
    sector,
    segment,
    service_line,
    service,
    theme_topic,
    market,
    career_stage,
    workplace,
  } = await getTranslations(locale);
  setState.setLocale(locale);
  setState.setAria(aria);
  setState.setCategory(category);
  setState.setCommon(common);
  setState.setContentType(content_type);
  setState.setLocation(location);
  setState.setMultiple(multiple);
  setState.setSector(sector);
  setState.setSegment(segment);
  setState.setServiceLine(service_line);
  setState.setService(service);
  setState.setThemeTopic(theme_topic);
  setState.setUrl(url);
  setState.setMarket(market);
  setState.setCareerStage(career_stage);
  setState.setWorkplace(workplace);
  setState.setIsLoading(false);
  setState.setIsReady(true);
};
