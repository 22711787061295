import {
  ColorBlockHero,
  Contact,
  ContentPage,
  ContentPageActor,
  Fact,
  MediaBackgroundHero,
  MediaContent,
  OptionBlock,
  TextModule,
} from 'models';
import { normalize } from 'pages/api/sitemap';
import { Translations } from 'store/translations';
import { internalPages } from 'utils/getInternalPage';

export const hrefLink = (
  model:
    | ContentPage
    | TextModule
    | MediaContent
    | Fact
    | ColorBlockHero
    | MediaBackgroundHero
    | ContentPageActor
    | Contact
    | OptionBlock,
  routerSlug: string | string[] | undefined,
  isContentPage = false,
  locale = process.env.NEXT_PUBLIC_DEFAULT_LOCALE!,
  translations?: Translations,
) => {
  const kontentAiItem = isContentPage
    ? (model as ContentPage | ContentPageActor)
    : (model as TextModule | MediaContent | Fact | ColorBlockHero | MediaBackgroundHero).elements.ctaSnippetKontentAiUrl
        ?.linkedItems[0];
  let url = '';
  if (
    !isContentPage &&
    (model as TextModule | MediaContent | Fact | ColorBlockHero | MediaBackgroundHero).elements.ctaSnippetUrl.value
  )
    url = (model as TextModule | MediaContent | Fact | ColorBlockHero | MediaBackgroundHero).elements.ctaSnippetUrl
      .value;
  else if (kontentAiItem) {
    if (kontentAiItem?.system?.type === 'content_page') {
      const campaignPage = (kontentAiItem as ContentPage).elements.partOfCampaignPage.linkedItems[0] as
        | string
        | ContentPage
        | undefined;
      const campaignUrl =
        campaignPage === '[Circular]' && Array.isArray(routerSlug)
          ? `/${routerSlug}`
          : `/${(campaignPage as ContentPage)?.elements?.url.value}`;

      if (!campaignUrl.includes('undefined')) {
        url = `${locale === process.env.NEXT_PUBLIC_DEFAULT_LOCALE! ? '' : `/${locale}`}${campaignUrl}/${
          (kontentAiItem as ContentPage).elements.url.value
        }`;
      } else if (!url)
        url =
          normalize(kontentAiItem as ContentPage, locale, undefined, false, translations && translations)?.[
            kontentAiItem.system?.codename
          ] ?? '';
    } else if (kontentAiItem?.system?.type === 'content_page_actor') {
      if ((kontentAiItem as ContentPageActor).elements.externalCtaLink.value)
        url = (kontentAiItem as ContentPageActor).elements.externalCtaLink.value;
      else {
        url =
          (translations &&
            internalPages(
              translations,
              (kontentAiItem as ContentPageActor).elements.internalCtaLink.value[0]?.codename,
            )) ??
          '';
      }
    } else
      url =
        normalize(kontentAiItem as ContentPage, locale, undefined, false, translations && translations)?.[
          kontentAiItem.system?.codename
        ] ?? '';
  }
  return url;
};
