import { Box, ChakraProps } from '@chakra-ui/react';
import React, { FunctionComponent, ReactElement } from 'react';

import { IconObject, Style } from '../../lib/Icons';

interface IconCompProps {
  iconName: keyof typeof IconObject;
  styleName?: keyof Style;
  chakraStyle?: ChakraProps['sx'];
}

export const IconComp: FunctionComponent<IconCompProps> = ({ iconName, styleName, chakraStyle }): ReactElement => {
  const iconObject = IconObject[iconName];
  const customStyle = (styleName && iconObject.style[styleName]) ?? iconObject.style.default;
  return (
    <Box aria-hidden='true' sx={chakraStyle ?? customStyle}>
      {IconObject[iconName] && React.createElement(iconObject.icon)}
    </Box>
  );
};
