import { selectAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(selectAnatomy.keys);

const noBorderRadius = defineStyle({
  borderRadius: 0,
});

const lg = defineStyle({
  borderRadius: 0,
  fontSize: '16px',
});

const xl = defineStyle({
  fontSize: '16px',
  minH: '60px',
  pl: 'xxs',
  pr: 's',
});

const sizes = {
  xs: definePartsStyle({ field: noBorderRadius }),
  lg: definePartsStyle({ field: lg }),
  xl: definePartsStyle({ field: xl }),
};

export const Select = defineMultiStyleConfig({ sizes });
