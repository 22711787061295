import { Global } from '@emotion/react';
import React from 'react';

const Fonts = (): JSX.Element => (
  <Global
    styles={`
      /* latin */
      @font-face {
        font-family: 'HCo Gotham Rounded Light';
        src:url('/fonts/B091DEC0FA8ECB331.woff2') format('woff2'),
          url('/fonts/B091DEC0FA8ECB331.woff') format('woff');
        font-style: normal;
        font-display: swap;

      }
      /* latin */
      @font-face {
        font-family: 'HCo Gotham Rounded Book';
        src:url('/fonts/8A3BF724BD34E9110.woff2') format('woff2'),
          url('/fonts/8A3BF724BD34E9110.woff') format('woff');
          font-display: swap;
      }
      /* latin */
      @font-face {
        font-family: 'HCo Gotham Rounded Medium';
        src:url('/fonts/8F568C51C35302813.woff2') format('woff2'),
          url('/fonts/8F568C51C35302813.woff') format('woff');
          font-display: swap;
      }
      /* latin */
      @font-face {
        font-family: 'HCo Gotham Rounded Bold';
        src:url('/fonts/FE95703437E135722.woff2') format('woff2'),
          url('/fonts/FE95703437E135722.woff') format('woff');
        font-style: normal;
        font-display: swap;
      }
      /* raleway */
      @font-face {
        font-family: 'Raleway-Font';
        src: url('/public/fonts/Raleway-VariableFont_wght.ttf');
        font-weight: 700;
        font-style: normal;
        font-display: swap;
      }
    `}
  />
);

export default Fonts;
