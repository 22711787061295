import { defineStyle, StyleFunctionProps } from '@chakra-ui/react';

const linkWithIconBase = defineStyle({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
});

export const focusBase = defineStyle((props: StyleFunctionProps) => {
  return {
    _focusVisible: {
      color: props.colorScheme,
      borderColor: props.colorScheme,
      borderWidth: '0',
      boxShadow: '0 0 0 2px var(--chakra-colors-mountain-base)',
      pl: 'xxs',
    },
  };
});

export const hoverBase = defineStyle((props: StyleFunctionProps) => {
  return {
    _hover: {
      textDecoration: 'none',
      color: props.colorScheme,
    },
  };
});

const secondaryButton = defineStyle((props: StyleFunctionProps) => {
  return {
    ...linkWithIconBase,
    ...focusBase(props),
    ...hoverBase(props),
    textStyle: 'desktop.body.medium.s',
    color: props.colorScheme,
  };
});

export const disabledBase = defineStyle({
  color: 'mountain.60',
  cursor: 'not-allowed',
  _hover: {
    textDecoration: 'none',
  },
});

const secondaryButtonDisabled = defineStyle({
  ...linkWithIconBase,
  ...disabledBase,
  textStyle: 'mobile.body.s',
});

const menu = defineStyle((props: StyleFunctionProps) => {
  return {
    ...linkWithIconBase,
    ...hoverBase(props),
    _focus: {
      ...hoverBase(props),
    },
    textStyle: 'desktop.body.book.l',
    color: 'mountain.base',
  };
});

const menuDisabled = defineStyle({
  ...disabledBase,
  textStyle: 'desktop.body.book.l',
});

const text = defineStyle({
  textStyle: 'mobile.body.s',
  lineHeight: '24px',
  color: 'cyan.web',
  _hover: {
    color: 'cyan.a11y',
    textDecorationLine: 'none', //underline
  },
  _focus: {
    color: 'cyan.a11y',
    borderColor: 'cyan.a11y',
    borderWidth: '1px',
    borderStyle: 'solid',
    textDecorationLine: 'none', //underline
    boxShadow: 'none',
    pl: 'xxs',
  },
});

const textDisabled = defineStyle({
  color: 'mountain.base',
  _hover: {
    textDecoration: 'none',
  },
});

export const Link = {
  baseStyle: {
    w: 'fit-content',
    h: 'fit-content',
    py: 'xxs',
    pr: 'xxs',
    fontWeight: 400,
    borderRadius: 0,
    iconSpacing: 'xxs',
  },
  variants: {
    secondaryButton,
    secondaryButtonDisabled,
    menu,
    menuDisabled,
    text,
    textDisabled,
  },
  defaultProps: {
    colorScheme: 'cyan.web',
  },
};
