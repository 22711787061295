import { Center, IconButton, Input, InputGroup, InputRightElement } from '@chakra-ui/react';
import { IconComp } from 'components/base/IconComp';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useState } from 'react';
import { withTranslations } from 'store/translations';
import getQueryParameter from 'utils/getQueryParameter';

type MainSearchProps = {
  placeholder?: string;
  mode?: string;
  toggleSearch?: React.Dispatch<React.SetStateAction<boolean>>;
};

const MainSearch = ({ placeholder, mode = 'main', toggleSearch }: MainSearchProps) => {
  const { aria } = withTranslations();
  const router = useRouter();
  const [value, setValue] = useState<string>(getQueryParameter(router.query.query) || '');

  const handleOnChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  }, []);

  const handleOnEnter = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter' && value.trim()) {
        router.push(`/search?query=${encodeURIComponent(value.toLowerCase())}`);
        getQueryParameter(value);
        toggleSearch?.(false);
      }
    },
    [router, toggleSearch, value, getQueryParameter],
  );

  useEffect(() => {
    setValue(getQueryParameter(router.query.query) || '');
  }, [router.query.query]);

  const handleInitValueChange = useCallback(
    (newValue: string) => {
      setValue(newValue);
      getQueryParameter(newValue);
    },
    [getQueryParameter],
  );

  const isMainMode = mode == 'main';

  const handleOnClear = () => setValue('');

  const searchCancelButtonCSS = {
    appearance: 'none',
    WebkitAppearance: 'none',
  };

  return (
    <Center
      px={isMainMode ? { base: 'xs', lg: '0' } : { base: 0 }}
      py={isMainMode ? { base: 'm', lg: 'xl' } : { base: 0 }}
      w='100%'
    >
      <InputGroup
        borderBottom='1px solid'
        borderColor='mountain.20'
        color='mountain.base'
        size={{ base: 'sm', lg: 'lg' }}
        variant='flushed'
        w={isMainMode ? { base: '100%', lg: '850px' } : { base: '100%' }}
      >
        <Input
          autoComplete='off'
          border='0'
          fontSize={{ base: '16px !important', lg: '30px !important' }}
          placeholder={placeholder}
          pr={{ base: 's !important', md: 'xl !important' }}
          sx={{
            '::-webkit-search-cancel-button': searchCancelButtonCSS,
            '::-webkit-search-decoration': searchCancelButtonCSS,
            '::-webkit-search-results-button': searchCancelButtonCSS,
            '::-webkit-search-results-decoration': searchCancelButtonCSS,
          }}
          textStyle={{ base: 'mobile.body.medium.s', lg: 'desktop.h5' }}
          type='search'
          value={value}
          _placeholder={{
            color: 'mountain.60',
            opacity: '1',
          }}
          _focus={{
            borderRadius: '0',
          }}
          onChange={handleOnChange}
          onKeyDown={handleOnEnter}
        />
        {!!value && (
          <InputRightElement bottom='0'>
            <IconButton
              aria-label={aria('clearInput')}
              icon={
                <IconComp
                  chakraStyle={{
                    h: { base: '22px', lg: '28px' },
                    w: { base: '22px', lg: '28px' },
                  }}
                  iconName='close'
                />
              }
              size={{ base: 'xs', lg: 'md' }}
              variant='unstyled'
              onClick={handleOnClear}
            />
            <IconButton
              aria-label={aria('search')}
              display={{ base: 'none', md: 'inline-flex' }}
              icon={
                <IconComp
                  chakraStyle={{
                    h: { base: '18px', lg: '24px' },
                    w: { base: '18px', lg: '24px' },
                  }}
                  iconName='chevronRight'
                />
              }
              size={{ base: 'xs', lg: 'md' }}
              variant='ghost'
              onClick={() => handleInitValueChange(value)}
            />
          </InputRightElement>
        )}
      </InputGroup>
    </Center>
  );
};

export default MainSearch;
