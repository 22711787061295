import { avatarAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react';

const { defineMultiStyleConfig, definePartsStyle } = createMultiStyleConfigHelpers(avatarAnatomy.keys);

const xl = defineStyle({
  fontSize: '6xl',
  height: '80px',
  width: '80px',
});

const xl2 = defineStyle({
  fontSize: '6xl',
  height: '100px',
  width: '100px',
});

const xl3 = defineStyle({
  fontSize: '5xl',
  height: 160,
  width: 160,
});

const xl4 = defineStyle({
  fontSize: '6xl',
  height: 192,
  width: 192,
});
const xl5 = defineStyle({
  fontSize: '7xl',
  height: 205,
  width: 205,
});

const sizes = {
  xl: definePartsStyle({ container: xl }),
  '2xl': definePartsStyle({ container: xl2 }),
  '3xl': definePartsStyle({ container: xl3 }),
  '4xl': definePartsStyle({ container: xl4 }),
  '5xl': definePartsStyle({ container: xl5 }),
};

export const Avatar = defineMultiStyleConfig({ sizes });
