import getT from 'next-translate/getT';
import { Translations } from 'store/translations';

export const getTranslations = async (locale = process.env.NEXT_PUBLIC_DEFAULT_LOCALE!) => {
  const aria = await getT(locale, ['aria', 'english']);
  const category = await getT(locale, ['category', 'english']);
  const common = await getT(locale, ['common', 'english']);
  const content_type = await getT(locale, ['content_type', 'english']);
  const location = await getT(locale, ['location', 'english']);
  const multiple = await getT(locale, ['multiple', 'english']);
  const sector = await getT(locale, ['sector', 'english']);
  const segment = await getT(locale, ['segment', 'english']);
  const service_line = await getT(locale, ['service_line', 'english']);
  const service = await getT(locale, ['service', 'english']);
  const theme_topic = await getT(locale, ['theme_topic', 'english']);
  const url = await getT(locale, ['url', 'english']);
  const market = await getT(locale, ['market', 'english']);
  const workplace = await getT(locale, ['workplace', 'english']);
  const career_stage = await getT(locale, ['career_stage', 'english']);

  return {
    locale,
    url,
    aria,
    category,
    common,
    content_type,
    location,
    multiple,
    sector,
    segment,
    service_line,
    service,
    theme_topic,
    market,
    workplace,
    career_stage,
  } as Translations;
};
