module.exports = {
  locales: ['en', 'en-us', 'en-gb', 'fi-fi', 'da-dk', 'sv-se', 'no-no', 'de-de', 'en-apac'],
  defaultLocale: 'en',
  localeDetection: false,
  defaultNS: 'common',
  pages: {
    '/[mainSubpage]': ({ params, locale }) => {
      return locale.includes('da-dk') && params.mainSubpage.includes('karriere') ? ['english'] : [];
    },
    '*': [
      'aria',
      'category',
      'common',
      'content_type',
      'location',
      'multiple',
      'sector',
      'segment',
      'service_line',
      'service',
      'theme_topic',
      'url',
      'workplace',
      'market',
      'career_stage',
    ],
  },
};
