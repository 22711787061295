export const Radio = {
  baseStyle: {
    container: {
      w: 'fit-content',
      h: 'fit-content',
      px: '5px',
      py: '3px',
      _focusWithin: {
        borderColor: 'cyan.web',
        borderWidth: '1px',
        px: '4px',
        py: '2px',
      },
      '.chakra-radio__control': {
        w: '20px',
        h: '20px',
      },
      _hover: {
        '.chakra-radio__control': {
          color: 'white',
          borderWidth: '2px',
          borderColor: 'cyan.web',
          bg: 'white',
          _checked: {
            borderWidth: '0px',
            bg: 'cyan.a11y',
            _disabled: {
              bg: 'mountain.40',
            },
          },
          _disabled: {
            borderColor: 'mountain.60',
          },
        },
      },
    },
    control: {
      mr: 'xxs',
      borderWidth: '2px',
      borderColor: 'mountain.base',
      _disabled: {
        borderColor: 'mountain.60',
        bg: 'white',
        _checked: {
          border: 'none',
          bg: 'mountain.40',
          color: 'white',
        },
      },
      _checked: {
        border: 'none',
        bg: 'cyan.web',
        color: 'white',
      },
      _focus: {
        borderColor: 'cyan.web',
        boxShadow: 'none',
      },
      _invalid: {
        borderColor: 'field',
      },
    },
    label: {
      m: '0',
      textStyle: 'mobile.body.s',
      color: 'mountain.base',
      _disabled: {
        color: 'mountain.60',
        opacity: 1,
      },
    },
  },
};
