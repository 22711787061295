export const Checkbox = {
  baseStyle: {
    container: {
      px: '5px',
      py: '3px',
      _checked: {
        _hover: {
          color: 'mountain.base',
        },
        _focusWithin: {
          borderColor: 'cyan.web',
          borderWidth: '1px',
          color: 'mountain.base',
        },
      },
      _hover: {
        color: 'cyan.web',
        '.chakra-checkbox__control': {
          bg: 'white',
          borderColor: 'cyan.web',
          borderWidth: '2px',
          _checked: {
            bg: 'cyan.a11y',
            _disabled: {
              bg: 'mountain.40',
            },
          },
          _disabled: {
            borderColor: 'mountain.60',
          },
        },
      },
      color: 'mountain.base',
      _focusWithin: {
        borderColor: 'cyan.web',
        borderWidth: '1px',
        color: 'cyan.web',
      },
    },
    control: {
      mt: '3px',
      alignSelf: 'start',
      width: '20px',
      height: '20px',
      borderColor: 'mountain.base',
      borderWidth: '2px',
      mr: 'xxs',
      _checked: {
        bg: 'cyan.web',
        border: 'none',
      },
      _disabled: {
        _checked: {
          border: 'none',
          bg: 'mountain.40',
        },
        borderColor: 'mountain.60',
        bg: 'white',
      },
      _focus: {
        _checked: {
          border: 'none',
          bg: 'cyan.web',
        },
        borderColor: 'cyan.web',
        boxShadow: 'none',
      },
      _invalid: {
        borderColor: 'field',
      },
    },
    label: {
      m: '0',
      textStyle: 'mobile.body.s',
      _disabled: {
        color: 'mountain.60',
        opacity: 1,
      },
    },
  },
};
