export function handleLanguageChange(
  localeCodename: string,
  cookie: { NEXT_LOCALE?: any },
  setCookie: (
    name: 'NEXT_LOCALE',
    value: any,
    options?: import('universal-cookie').CookieSetOptions | undefined,
  ) => void,
) {
  if (cookie.NEXT_LOCALE !== localeCodename) setCookie('NEXT_LOCALE', localeCodename, { path: '/' });

  // if there are no variants or translated variant of the selected locale does not exist redirect to homepage of selected locale
  /* if (!clearedVariants || !variantPageSlug || variantPageSlug === '') {
    location.href = '/';
  } else {
    location.href = variantPageSlug ?? '/';
  } */
}

/* function getClearedVariants(variants: { [key: string]: string } | undefined) {
  if (!variants) {
    return undefined;
  }

  const result = {};

  for (const [key, value] of Object.entries(variants)) {
    result[key] = Object.entries(result)
      .map((entry) => entry[1])
      .includes(value)
      ? ''
      : value;
  }

  return result;
} */
