import { extendTheme, ThemeConfig } from '@chakra-ui/react';

import { Accordion } from './components/accordion';
import { Avatar } from './components/avatar';
import { Button } from './components/button';
import { Checkbox } from './components/checkbox';
import { Container } from './components/container';
import { Form } from './components/form';
import { inputTheme } from './components/input';
import { Link } from './components/link';
import { Modal } from './components/modal';
import { Radio } from './components/radio';
import { Select } from './components/select';
import { Tabs } from './components/tabs';
import { textareaTheme } from './components/textarea';
import { breakpoints, colors, fonts, spacing, textStyles } from './overrides';

const config: ThemeConfig = {
  initialColorMode: 'light',
  useSystemColorMode: false,
};

const lightTheme = extendTheme({
  colors,
  ...spacing,
  fonts,
  textStyles,
  config,
  breakpoints,
  components: {
    Accordion,
    Avatar,
    Button,
    Checkbox,
    Container,
    Link,
    Modal,
    Form,
    Input: inputTheme,
    Textarea: textareaTheme,
    Radio,
    Select,
    Tabs,
  },
});

export default lightTheme;
