import { Button, CloseButton, Collapse, Flex, Select, Text, useDisclosure } from '@chakra-ui/react';
import { languageMapper } from 'lib/languages';
import { LocationPopout, NavigationLanguageItem } from 'models';
import { useRouter } from 'next/router';
import { FC, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { handleLanguageChange } from 'utils/handleLocaleChange';
import { GetContentPage } from 'utils/sitemap';

export type LocationPopupProps = {
  languages: NavigationLanguageItem[];
  variants?: Awaited<ReturnType<typeof GetContentPage.allLocales>>;
};

export const LocationPopup: FC<LocationPopupProps> = ({ languages, variants }) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [location, setLocation] = useState<keyof typeof languageMapper | null>(null);
  const [popupContent, setPopupContent] = useState<LocationPopout['elements']['description'] | null>(null);
  const [selectOptions, setSelectOptions] = useState<NavigationLanguageItem[] | null>(null);
  const { locale, push, asPath } = useRouter();
  const [cookie, setCookie, removeCookie] = useCookies();
  const [referrer, setReferrer] = useState('');

  useEffect(() => {
    if (document.referrer) {
      setReferrer(document.referrer);
      return;
    }
    const fetchGeoLocation = async () => {
      const response = await fetch('/api/geo-location');
      const data = await response.json();
      const geoLocation = languageMapper[data];
      setLocation(cookie.INITIAL_LOCALE === cookie.NEXT_LOCALE ? geoLocation ?? null : cookie.INITIAL_LOCALE);
    };

    fetchGeoLocation();
  }, [cookie.NEXT_LOCALE]);

  useEffect(() => {
    if (
      !location ||
      locale === location ||
      !!referrer ||
      !cookie.INITIAL_LOCALE ||
      cookie.INITIAL_LOCALE === cookie.NEXT_LOCALE
    )
      return;

    const fetchPopupContent = async () => {
      const content: LocationPopout['elements']['description'] = await fetch(
        `/api/location-popup?locale=${location}`,
      ).then((response) => response.json());

      const options = languages;

      setSelectOptions(options);
      setPopupContent(content);
    };

    fetchPopupContent();
  }, [cookie.NEXT_LOCALE, locale, location]);

  useEffect(() => {
    if (!popupContent) return;

    onOpen();
  }, [onOpen, popupContent]);

  return (
    <Collapse in={isOpen}>
      <Flex
        position={'relative'}
        zIndex={999}
        align={{ bas: 'start', md: 'center' }}
        bg='cyan.10'
        direction={{ base: 'column', md: 'row' }}
        gap={{ base: 'xxs', md: 's' }}
        px={{ base: 'xs', md: 's' }}
        py={{ base: 'xs', md: 'xxs' }}
      >
        {popupContent?.value && (
          <Flex align='center' flex={1}>
            <Text color='mountain.base' textStyle={{ base: 'mobile.body.medium.xs', md: 'desktop.body.medium.s' }}>
              {popupContent?.value}
            </Text>
            <CloseButton
              display={{ base: 'flex', md: 'none' }}
              ml='xxs'
              onClick={() => {
                removeCookie('INITIAL_LOCALE');
                onClose();
              }}
            />
          </Flex>
        )}
        <Flex align='center'>
          {selectOptions && (
            <Select
              bg='white'
              borderColor='mountain.20'
              color='mountain.base'
              w='216px'
              mr={{ base: 'xs', md: 'xxs' }}
              size={{ base: 'lg', md: 'xl' }}
              textStyle='desktop.body.medium.s'
              value={location ?? ''}
              onChange={(e) => setLocation(e.target.value as keyof typeof languageMapper)}
            >
              {selectOptions.map((country) => (
                <option key={country.system.id} value={country.elements.languageCodename.value}>
                  {country.elements.title.value}
                </option>
              ))}
            </Select>
          )}
          <Button
            size={{ base: 'lg', md: 'medium' }}
            borderRadius='4px'
            onClick={() => {
              if (location) {
                const variant = variants?.[location] || asPath;
                handleLanguageChange(location, cookie, setCookie);
                push(variant, variant, { locale: location });
              }
              removeCookie('INITIAL_LOCALE');
              onClose();
            }}
          >
            Continue
          </Button>
          <CloseButton
            display={{ base: 'none', md: 'flex' }}
            ml='s'
            onClick={() => {
              removeCookie('INITIAL_LOCALE');
              onClose();
            }}
          />
        </Flex>
      </Flex>
    </Collapse>
  );
};

// handleLanguageChange(location, cookie, setCookie)
