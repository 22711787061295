import Alert from '../../public/icons/alert.svg';
import Article from '../../public/icons/article.svg';
import Calendar from '../../public/icons/calendar.svg';
import Cast from '../../public/icons/cast.svg';
import Check from '../../public/icons/check.svg';
import ChevronDown from '../../public/icons/chevron-down.svg';
import ChevronLeft from '../../public/icons/chevron-left.svg';
import ChevronRight from '../../public/icons/chevron-right.svg';
import ChevronRightForLink from '../../public/icons/chevron-right-for-link.svg';
import Close from '../../public/icons/close.svg';
import DoubleChevronLeft from '../../public/icons/double-chevron-left.svg';
import DoubleChevronRight from '../../public/icons/double-chevron-right.svg';
import Download from '../../public/icons/download.svg';
import Facebook from '../../public/icons/facebook.svg';
import File from '../../public/icons/file.svg';
import FileText from '../../public/icons/file-text.svg';
import FeaturedProject from '../../public/icons/googleMaps/featuredProject.svg';
import OfficeLocal from '../../public/icons/googleMaps/officeLocal.svg';
import OfficeMain from '../../public/icons/googleMaps/officeMain.svg';
import Home from '../../public/icons/home.svg';
import Linkedin from '../../public/icons/linkedin.svg';
import List from '../../public/icons/list.svg';
import Map from '../../public/icons/map.svg';
import Menu from '../../public/icons/menu.svg';
import Message from '../../public/icons/message.svg';
import Minus from '../../public/icons/minus.svg';
import Next from '../../public/icons/next.svg';
import Pause from '../../public/icons/pause.svg';
import Pin from '../../public/icons/pin.svg';
import Play from '../../public/icons/play.svg';
import Plus from '../../public/icons/plus.svg';
import Podcast from '../../public/icons/podcast.svg';
import Prev from '../../public/icons/prev.svg';
import Ramboll from '../../public/icons/ramboll.svg';
import Rotate from '../../public/icons/rotate.svg';
import Search from '../../public/icons/search.svg';
import Share from '../../public/icons/share.svg';
import Featured from '../../public/icons/star.svg';
import Trash from '../../public/icons/trash.svg';
import Twitter from '../../public/icons/twitter.svg';
import Upload from '../../public/icons/upload.svg';
import Video from '../../public/icons/video.svg';
import Youtube from '../../public/icons/youtube.svg';

export type Style = {
  default: object;
  disabled?: object;
  active?: object;
};

export const IconObject = {
  doubleChevronLeft: {
    icon: DoubleChevronLeft,
    style: {
      default: {
        position: 'relative',
        display: 'inline-block',
        top: '3xs',
        path: {
          stroke: 'mountain.base',
        },
        h: '24px',
        w: '24px',
      },
      active: {
        path: {
          stroke: 'cyan.web',
        },
      },
      disabled: {
        position: 'relative',
        display: 'inline-block',
        top: '3xs',
        path: {
          stroke: 'mountain.60',
        },
        h: '24px',
        w: '24px',
      },
    },
  },
  doubleChevronRight: {
    icon: DoubleChevronRight,
    style: {
      default: {
        position: 'relative',
        display: 'inline-block',
        top: '3xs',
        path: {
          stroke: 'mountain.base',
        },
        h: '24px',
        w: '24px',
      },
      active: {
        path: {
          stroke: 'cyan.web',
        },
      },
      disabled: {
        position: 'relative',
        display: 'inline-block',
        top: '3xs',
        path: {
          stroke: 'mountain.60',
        },
        h: '24px',
        w: '24px',
      },
    },
  },
  alert: {
    icon: Alert,
    style: {
      default: {
        path: { fill: 'field' },
      },
    } as Style,
  },
  message: {
    icon: Message,
    style: {
      default: {
        path: {
          stroke: 'white',
          strokeWidth: '2px',
        },
      },
      disabled: {
        path: {
          stroke: 'mountain.60',
          strokeWidth: '2px',
        },
      },
    } as Style,
  },
  download: {
    icon: Download,
    style: {
      default: {
        path: {
          stroke: 'white',
          fill: 'white',
        },
      },
      disabled: {
        path: {
          stroke: 'mountain.60',
          fill: 'mountain.60',
        },
      },
    } as Style,
  },
  chevronRight: {
    icon: ChevronRight,
    style: {
      default: {
        position: 'relative',
        display: 'inline-block',
        top: '3xs',
        path: {
          stroke: 'mountain.base',
        },
        h: '24px',
        w: '24px',
      },
      active: {
        path: {
          stroke: 'cyan.web',
        },
      },
      disabled: {
        position: 'relative',
        display: 'inline-block',
        top: '3xs',
        path: {
          stroke: 'mountain.60',
        },
        h: '24px',
        w: '24px',
      },
    } as Style,
  },
  chevronRightForLink: {
    icon: ChevronRightForLink,
    style: {
      default: {},
    } as Style,
  },
  chevronLeft: {
    icon: ChevronLeft,
    style: {
      default: {
        position: 'relative',
        display: 'inline-block',
        top: '3xs',
        path: {
          stroke: 'mountain.base',
        },
        h: '24px',
        w: '24px',
      },
      active: {
        path: {
          stroke: 'cyan.web',
        },
      },
      disabled: {
        position: 'relative',
        display: 'inline-block',
        top: '3xs',
        path: {
          stroke: 'mountain.60',
        },
        h: '24px',
        w: '24px',
      },
    } as Style,
  },
  article: {
    icon: Article,
    style: {
      default: {
        path: {
          fill: 'white',
        },
        pr: '2px',
        svg: {
          transform: 'scale(0.9)',
        },
      },
    } as Style,
  },
  podcast: {
    icon: Podcast,
    style: {
      default: {
        path: {
          fill: 'white',
        },
        svg: {
          transform: 'scale(0.9)',
        },
        pr: '2px',
      },
    } as Style,
  },
  video: {
    icon: Video,
    style: {
      default: {
        path: {
          fill: 'white',
        },
        pr: '2px',
        svg: {
          transform: 'scale(0.9)',
        },
      },
    } as Style,
  },
  youtube: {
    icon: Youtube,
    style: {
      default: {
        path: {
          fill: 'white',
        },
        pr: '5px',
        svg: {
          transform: 'scale(0.9)',
        },
      },
    } as Style,
  },
  featured: {
    icon: Featured,
    style: {
      default: {
        path: {
          stroke: 'var(--chakra-colors-cyan-web)',
        },
        pr: '2px',
        svg: {
          transform: 'scale(0.9)',
        },
      },
    } as Style,
  },
  check: {
    icon: Check,
    style: {
      default: {
        path: {
          stroke: 'white',
          strokeWidth: '2px',
        },
        transform: 'scale(0.5)',
      },
    } as Style,
  },
  minus: {
    icon: Minus,
    style: {
      default: {
        path: {
          stroke: 'white',
          strokeWidth: '2px',
        },
        transform: 'scale(0.5)',
      },
      active: {},
    } as Style,
  },
  plus: {
    icon: Plus,
    style: {
      default: {
        path: {
          stroke: 'white',
          strokeWidth: '2px',
        },
        transform: 'scale(0.5)',
      },
      active: {},
    } as Style,
  },
  officeLocal: {
    icon: OfficeLocal,
    style: {
      default: {},
    } as Style,
  },
  officeMain: {
    icon: OfficeMain,
    style: {
      default: {},
    } as Style,
  },
  featuredProject: {
    icon: FeaturedProject,
    style: {
      default: {},
    } as Style,
  },
  share: {
    icon: Share,
    style: {
      default: {},
    } as Style,
  },
  list: {
    icon: List,
    style: {
      default: {
        path: {
          stroke: 'mountain.base',
        },
        transform: 'scale(0.7)',
      },
      active: {
        path: {
          stroke: 'cyan.web',
        },
        transform: 'scale(0.7)',
      },
    } as Style,
  },
  map: {
    icon: Map,
    style: {
      default: {
        path: {
          stroke: 'mountain.base',
        },
        transform: 'scale(0.7)',
      },
      active: {
        path: {
          stroke: 'cyan.web',
        },
        transform: 'scale(0.7)',
      },
    } as Style,
  },
  ramboll: {
    icon: Ramboll,
    style: {
      default: {},
    } as Style,
  },
  rotate: {
    icon: Rotate,
    style: {
      default: {
        h: '36px',
        w: '36px',
      },
    },
  },
  search: {
    icon: Search,
    style: {
      default: {
        h: '24px',
        w: '24px',
      },
    } as Style,
  },
  menu: {
    icon: Menu,
    style: {
      default: {},
    } as Style,
  },
  pin: {
    icon: Pin,
    style: {
      default: {
        transform: 'scale(1.2)',
        display: 'inline-block',
        '@media only screen and (min-width: 1024px)': {
          transform: 'scale(0.7)',
        },
      },
    } as Style,
  },
  facebook: {
    icon: Facebook,
    style: {
      default: {},
    } as Style,
  },
  twitter: {
    icon: Twitter,
    style: {
      default: {},
    } as Style,
  },
  linkedin: {
    icon: Linkedin,
    style: {
      default: {},
    } as Style,
  },
  file: {
    icon: File,
    style: {
      default: {},
    } as Style,
  },
  play: {
    icon: Play,
    style: {
      default: {
        w: { base: '24px', md: '80px' },
        h: { base: '24px', md: '80px' },
      },
    } as Style,
  },
  pause: {
    icon: Pause,
    style: {
      default: {
        w: { base: '24px', md: '80px' },
        h: { base: '24px', md: '80px' },
      },
    } as Style,
  },
  prev: {
    icon: Prev,
    style: {
      default: {
        borderColor: 'mountain.base',
        borderWidth: '0.5px',
        borderRadius: '50%',
        p: 'xxs',
        path: {
          fill: 'mountain.base',
        },
      },
      disabled: {
        borderColor: 'mountain.40',
        borderWidth: '0.5px',
        borderRadius: '50%',
        p: 'xxs',
        path: {
          fill: 'mountain.40',
        },
      },
    } as Style,
  },
  next: {
    icon: Next,
    style: {
      default: {
        borderColor: 'mountain.base',
        borderWidth: '0.5px',
        borderRadius: '50%',
        p: 'xxs',
        path: {
          fill: 'mountain.base',
        },
      },
      disabled: {
        borderColor: 'mountain.40',
        borderWidth: '0.5px',
        borderRadius: '50%',
        p: 'xxs',
        path: {
          fill: 'mountain.40',
        },
      },
    } as Style,
  },
  close: {
    icon: Close,
    style: {
      default: {
        h: '24px',
        w: '24px',
      },
    } as Style,
  },
  home: {
    icon: Home,
    style: {} as Style,
  },
  chevronDown: {
    icon: ChevronDown,
    style: {
      default: {
        transform: 'scale(0.8)',
      },
    } as Style,
  },
  event: {
    icon: Calendar,
    style: {
      default: {
        path: {
          fill: 'white',
        },
        pr: '2px',
        svg: {
          transform: 'scale(0.9)',
        },
      },
    } as Style,
  },
  webinar: {
    icon: Cast,
    style: {
      default: {
        path: {
          strokeWidth: '1.5px',
        },
        pr: '2px',
        svg: {
          transform: 'scale(0.9)',
        },
      },
    } as Style,
  },
  global_news: {
    icon: FileText,
    style: {
      default: {
        path: {
          strokeWidth: '1.5px',
        },
        pr: '2px',
        svg: {
          transform: 'scale(0.9)',
        },
      },
    } as Style,
  },
  financial_news: {
    icon: FileText,
    style: {
      default: {
        path: {
          strokeWidth: '1.5px',
        },
        pr: '2px',
        svg: {
          transform: 'scale(0.9)',
        },
      },
    } as Style,
  },
  other_news: {
    icon: FileText,
    style: {
      default: {
        path: {
          strokeWidth: '1.5px',
        },
        pr: '2px',
        svg: {
          transform: 'scale(0.9)',
        },
      },
    } as Style,
  },
  white_papers: {
    icon: FileText,
    style: {
      default: {
        path: {
          strokeWidth: '1.5px',
        },
        pr: '2px',
        svg: {
          transform: 'scale(0.9)',
        },
      },
    } as Style,
  },
  press_release: {
    icon: FileText,
    style: {
      default: {
        path: {
          strokeWidth: '1.5px',
        },
        pr: '2px',
        svg: {
          transform: 'scale(0.9)',
        },
      },
    } as Style,
  },
  trash: {
    icon: Trash,
    style: {} as Style,
  },
  upload: {
    icon: Upload,
    style: {} as Style,
  },
};
