import { Box, Button, Flex, Image, Link, List, ListItem, StackDivider, Text, VStack } from '@chakra-ui/react';
import { moduleMaxWidth } from 'themes/overrides';

type Column = {
  href: string;
  id: string;
  text: string;
  openIn?: string;
};

export type FooterProps = {
  column1?: Column[];
  column2?: Column[];
  column3?: Column[];
  descriptionHtml: string;
  isCampaign?: boolean;
  logo: {
    alt: string | null;
    src: string;
  };
  policies?: Column[];
  slogan: string;
  title: string;
  ctaText?: string;
  ctaOpenIn?: string;
  ctaUrl?: string;
};

const Footer = ({
  column1,
  column2,
  column3,
  descriptionHtml,
  isCampaign,
  logo,
  policies,
  slogan,
  title,
  ctaText,
  ctaOpenIn,
  ctaUrl,
}: FooterProps) => {
  const listProps = {
    color: 'mountain.base',
    spacing: { base: 's', md: 'xs' },
    textStyle: { base: 'mobile.body.s', md: 'desktop.body.l' },
  };

  return (
    <VStack
      w='100vw'
      maxW={moduleMaxWidth}
      mx='auto'
      align='stretch'
      as='footer'
      divider={<StackDivider as='hr' color='mountain.10' />}
      pt={isCampaign ? { base: 's', lg: 'm' } : { base: 's', md: 'l' }}
      pb={isCampaign ? { base: 's', lg: 'l' } : { base: 's', md: 'l' }}
      px={{ base: 'xs', md: 'l' }}
      spacing={{
        base: 's',
        md: 'm',
      }}
    >
      {!isCampaign && (
        <Flex direction={{ base: 'column', lg: 'row' }}>
          {/* -------------- LEFT COLUMN (GET OUR LATEST INSIGHTS) -------------- */}
          <Box color='ocean.base' flex='1' pb={{ base: 's', lg: 0 }} pr={{ md: 'm', lg: 'l' }}>
            <Text as='h2' textStyle={{ base: 'mobile.h4', md: 'desktop.h5' }}>
              {title}
            </Text>
            <Box
              dangerouslySetInnerHTML={{ __html: descriptionHtml }}
              my={{ base: 'xs', md: 'xs' }}
              textStyle={{ base: 'mobile.body.s', md: 'desktop.body.book.l' }}
            />
            {ctaText && ctaUrl && ctaOpenIn && (
              <Button
                as='a'
                target={ctaOpenIn === 'new_tab' ? '_blank' : ''}
                href={ctaUrl}
                fontWeight='400'
                textStyle='mobile.body.medium.s'
                variant='link'
                color='ocean'
                borderRadius='4px'
              >
                {ctaText}
              </Button>
            )}
          </Box>
          {/* -------------- LEFT COLUMN (GET OUR LATEST INSIGHTS) -------------- */}

          {/* -------------- RIGHT ROWS -------------- */}
          <Flex direction={{ base: 'column', md: 'row' }}>
            <List {...listProps} pb={{ base: 's', md: 0 }} pr={{ md: 'xl', lg: 'l' }}>
              {column1?.map(({ href, id, text, openIn }) => (
                <ListItem key={id} textStyle={{ base: 'desktop.body.book.s', lg: 'desktop.body.book.l' }}>
                  <Link as={'a'} href={href} isExternal={openIn === 'new_tab'}>
                    {text}
                  </Link>
                </ListItem>
              ))}
            </List>
            <Flex>
              <List {...listProps} pr={{ base: 's', md: 'xl', lg: 'l' }}>
                {column2?.map(({ href, id, text, openIn }) => (
                  <ListItem key={id} textStyle={{ base: 'desktop.body.book.s', lg: 'desktop.body.book.l' }}>
                    <Link as={'a'} href={href} isExternal={openIn === 'new_tab'}>
                      {text}
                    </Link>
                  </ListItem>
                ))}
              </List>
              <List {...listProps}>
                {column3?.map(({ href, id, text }) => (
                  <ListItem key={id} textStyle={{ base: 'desktop.body.book.s', lg: 'desktop.body.book.l' }}>
                    <Link isExternal as={'a'} href={href}>
                      {text}
                    </Link>
                  </ListItem>
                ))}
              </List>
            </Flex>
          </Flex>
          {/* -------------- RIGHT ROWS END -------------- */}
        </Flex>
      )}
      {/* -------------- BOTTOM COLUMN -------------- */}
      <Box>
        <Box
          color='cyan.web'
          dangerouslySetInnerHTML={{ __html: slogan }}
          mb='xs'
          textStyle={{ base: 'mobile.h3', md: 'mobile.h3' }}
        />
        <Image alt={logo.alt || 'Logo'} src={logo.src} maxWidth='147px' />
        <List color='mountain.base' mt={{ base: 'xs', md: 's' }} textStyle='mobile.body.xs'>
          <ListItem color='mountain.60' display='inline-block' mr='s'>
            <Text as='small' textStyle='mobile.body.xs'>
              &copy; {new Date().getFullYear()}
            </Text>
          </ListItem>
          {policies?.map(({ href, id, text }) => (
            <ListItem key={id} display='inline-block' mr='s' mt='s' whiteSpace='nowrap'>
              <Link as={'a'} href={href} p='0'>
                {text}
              </Link>
            </ListItem>
          ))}
        </List>
      </Box>
      {/* -------------- BOTTOM COLUMN END -------------- */}
    </VStack>
  );
};

export default Footer;
