import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';
const helpers = createMultiStyleConfigHelpers(['accordion', 'button', 'item', 'panel']);

export const Accordion = helpers.defineMultiStyleConfig({
  baseStyle: {
    button: {
      borderColor: 'mountain.20',
      textStyle: 'mobile.body.s',
      _hover: {
        bg: 'transparent',
      },
      pb: 'xs',
    },
    item: {
      borderColor: 'mountain.20',
    },
    container: {
      pt: 's',
      borderTopWidth: 0,
      borderBottomWidth: '1px',
    },
    panel: {
      textStyle: 'mobile.body.s',
      px: '0',
      pt: '0',
    },
  },
  sizes: {
    base: {
      button: {
        px: 0,
      },
      item: {
        borderTopWidth: 0,
        borderBottomWidth: '1px',
      },
    },
    md: {
      button: {
        py: 'xxs',
      },
    },
  },
  defaultProps: {
    size: 'base',
  },
});
