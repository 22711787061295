import { useRouter } from 'next/router';

export const shouldForceDefaultLocale = (router: ReturnType<typeof useRouter>) => {
  const forceDefaultLocaleMap = [
    {
      locale: 'da-dk',
      path: '/karriere',
    },
  ];
  const locale = router.locale ?? process.env.NEXT_PUBLIC_DEFAULT_LOCALE!;
  const path = router.asPath;
  const isForceDefaultLocale = forceDefaultLocaleMap.find((item) => item.locale === locale && path.includes(item.path));
  return !!isForceDefaultLocale;
};
