import { Box, Center, Container, Text } from '@chakra-ui/react';

export const HeadingsCheck = ({ docuementContent }: { docuementContent: string }) => {
  const getHeadings = (content: string) => {
    const headingRegex = /<(h[1-6])[^>]*>(.*?)<\/\1>/g;
    const matches = content.matchAll(headingRegex);

    const headings: JSX.Element[] = [];
    let index = 0;
    let previousLevel = 0;

    for (const match of matches) {
      const [, tagName, innerHTML] = match;
      const level = parseInt(tagName.charAt(1), 10);
      const text = innerHTML.replace(/<[^>]*>/g, '');

      const indentStyle = {
        paddingLeft: `${level}rem`,
        color: previousLevel !== 0 && level - previousLevel > 1 ? 'red' : 'black', // Compare level difference
        fontWeight: previousLevel !== 0 && level - previousLevel > 1 ? 'extrabold' : 'normal',
      };

      headings.push(
        <Box key={index++} pl={indentStyle.paddingLeft} color={indentStyle.color} fontWeight={indentStyle.fontWeight}>
          <Text>
            h{level} - {text}
          </Text>
        </Box>,
      );

      previousLevel = level;
    }

    return headings;
  };

  return (
    <Container>
      <Center my='s' textTransform={'uppercase'}>
        Headings structure
      </Center>
      <Text mb='s'>
        If anything is colored {<span style={{ color: 'red', fontWeight: 'bold' }}>red</span>}, that means the hierarchy
        is not followed correctly. Previous heading cannot be more than 1 level skip.
      </Text>
      {getHeadings(docuementContent)}
    </Container>
  );
};
